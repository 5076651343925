<template>
  <v-container fluid>
    <v-container fluid fill-height v-if="error && !displayQuestionnaire" >
      <v-row justify="space-around" class="mb-12">
        <span class="group pa-2">
          <v-icon x-large class="error">error</v-icon>
        </span>
      </v-row>
      <v-layout align-center justify-center>
        <div class="text-md-center">
          <h2 class="my-3 headline">Sorry, the item you are looking does not exist.</h2>
          <div>
            <v-btn color="primary" @click="$router.push({ path: '/Multimedia' })">Go Home</v-btn>
          </div>
        </div>
      </v-layout>
    </v-container>
    <v-row dense v-if="!displayQuestionnaire && !error">
      <v-col :cols="12">
        <p class="font-weight-medium">Please watch the video carefully. After the video, you are required to answer few questions.</p>

        <video v-if="multimedia.itemType === 'video'"
          width="100%"
          height="240"
          controls
          @ended="onEnd"
        >
          <source :src="multimedia.source" :type="multimedia.codec" />
        </video>

        <youtube v-if="multimedia.itemType === 'youtube'"
          :resize="true"
          :fitParent="true"
          :video-id="multimedia.videoId"
          ref="youtube"
          @ended="onEnd"
        />

        <audio v-if="multimedia.itemType === 'audio'"
          controls 
          preload="none" 
          @ended="onEnd"
        >
          <source :src="multimedia.source" :type="multimedia.codec" />
        </audio>
      </v-col>
    </v-row>
    <v-row dense v-if="displayQuestionnaire">
      <v-col :cols="12">
        <v-alert type="error" v-if="error" dismissible>
          There was an error in submitting your data. Please try again.
        </v-alert>
        <v-form ref="questionnaire">
          <div v-for="(question, idx) in multimedia.questions" :key="idx">
            <p>{{idx + 1}}. {{ $languageProcessor.translate(question.title) }}</p>

            <v-radio-group v-if="question.questionType == 'radio'" column
              :rules="[value => !!value || 'Please select an option.']"
              v-model="question.answer"
            >
              <v-radio v-for="(option, oIdx) in question.options" :key="oIdx"
                :label="$languageProcessor.translate(option.title)"
                :value="$languageProcessor.translate(option)"
              ></v-radio>
            </v-radio-group>

            <div v-if="question.questionType == 'rating'">
              <v-input :value="question.answer" :rules="[value => !!value || 'Please select an option.']">
                <v-rating 
                  large
                  v-model="question.answer"
                  :rules="[value => value > 0 || 'Please select an option.']"
                ></v-rating>
              </v-input>
            </div>
          </div>
        </v-form>
      </v-col>
      <v-btn color="primary" @click="submit">Submit</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { DataService } from "@/services/DataService";

const dataService = new DataService();

export default {
  data() {
    return {
      answers: [],
      error: false,
      multimedia: {},
      displayQuestionnaire: false,
    };
  },
  mounted() {
    this.$store.state.loader = true

    dataService.viewMultimedia(this.$store.state.token, {
      userId: this.$store.state.userProfile._id,
      multimediaId: this.$route.params.id
    }).then(m => {
      this.multimedia = m;
      this.$store.state.loader = false
    }).catch(() => {
      this.error = true;
      this.$store.state.loader = false
    });
  },
  methods: {
    questionProcessor(questions) {
      let processedData = {
        answers: [],
        disqualified: false
      }

      for (let question of questions) {
        if (question.questionType == "radio") {
          if (question.answer.disqualified != undefined && question.answer.disqualified== true) {
            processedData.disqualified = true
          }

          processedData.answers.push({
            title: this.$languageProcessor.getEnText(question.title),
            answer: this.$languageProcessor.getEnText(question.answer.title)
          })
        } else if (question.questionType == "rating") {          
          processedData.answers.push({
            title: this.$languageProcessor.getEnText(question.title),
            answer: question.answer
          })
        } else {
          console.log("Error - Question Type not handeled!")
        }
      }

      return processedData
    },
    onEnd() {
      this.displayQuestionnaire = true;
    },

    submit() {
      if (this.$refs.questionnaire.validate()) {
        this.$store.state.loader = true

        dataService.saveMultimediaResponse(this.$store.state.token, {
          userId: this.$store.state.userProfile._id,
          multimediaId: this.multimedia._id,
          ...this.questionProcessor(this.multimedia.questions)
        }).then(r => {
          this.$store.state.loader = false
          this.$router.push(`/Multimedia?points=${r.points}`)
        }).catch(() => {
          this.$store.state.loader = false
          this.error = true
        });
      }
    }
  }
};
</script>